.lst-kix_i20dswo3uxy1-7>li:before {
            content: "\0025cb  "
        }

        .lst-kix_i20dswo3uxy1-6>li:before {
            content: "\0025cf  "
        }

        .lst-kix_i20dswo3uxy1-8>li:before {
            content: "\0025a0  "
        }

        ul.lst-kix_i20dswo3uxy1-0 {
            list-style-type: none
        }

        .lst-kix_i20dswo3uxy1-5>li:before {
            content: "\0025a0  "
        }

        .lst-kix_i20dswo3uxy1-0>li:before {
            content: "\0025cf  "
        }

        .lst-kix_i20dswo3uxy1-1>li:before {
            content: "\0025cb  "
        }

        .lst-kix_qqsaobkzdtyb-7>li:before {
            content: "\0025cb  "
        }

        .lst-kix_i20dswo3uxy1-3>li:before {
            content: "\0025cf  "
        }

        ul.lst-kix_vz0v84nlmagn-0 {
            list-style-type: none
        }

        ul.lst-kix_vz0v84nlmagn-1 {
            list-style-type: none
        }

        .lst-kix_i20dswo3uxy1-2>li:before {
            content: "\0025a0  "
        }

        .lst-kix_i20dswo3uxy1-4>li:before {
            content: "\0025cb  "
        }

        .lst-kix_qqsaobkzdtyb-8>li:before {
            content: "\0025a0  "
        }

        ul.lst-kix_vz0v84nlmagn-8 {
            list-style-type: none
        }

        ul.lst-kix_vz0v84nlmagn-6 {
            list-style-type: none
        }

        .lst-kix_etv82t5b4bev-1>li:before {
            content: "\0025cb  "
        }

        ul.lst-kix_vz0v84nlmagn-7 {
            list-style-type: none
        }

        ul.lst-kix_vz0v84nlmagn-4 {
            list-style-type: none
        }

        ul.lst-kix_vz0v84nlmagn-5 {
            list-style-type: none
        }

        ul.lst-kix_vz0v84nlmagn-2 {
            list-style-type: none
        }

        .lst-kix_etv82t5b4bev-2>li:before {
            content: "\0025a0  "
        }

        ul.lst-kix_vz0v84nlmagn-3 {
            list-style-type: none
        }

        .lst-kix_ursa7dda2dhe-0>li:before {
            content: "\0025cf  "
        }

        .lst-kix_etv82t5b4bev-3>li:before {
            content: "\0025cf  "
        }

        .lst-kix_etv82t5b4bev-5>li:before {
            content: "\0025a0  "
        }

        .lst-kix_etv82t5b4bev-4>li:before {
            content: "\0025cb  "
        }

        .lst-kix_etv82t5b4bev-0>li:before {
            content: "\0025cf  "
        }

        ul.lst-kix_tlyqw5gcux9z-7 {
            list-style-type: none
        }

        ul.lst-kix_tlyqw5gcux9z-8 {
            list-style-type: none
        }

        ul.lst-kix_tlyqw5gcux9z-5 {
            list-style-type: none
        }

        ul.lst-kix_tlyqw5gcux9z-6 {
            list-style-type: none
        }

        ul.lst-kix_tlyqw5gcux9z-3 {
            list-style-type: none
        }

        ul.lst-kix_tlyqw5gcux9z-4 {
            list-style-type: none
        }

        ul.lst-kix_tlyqw5gcux9z-1 {
            list-style-type: none
        }

        ul.lst-kix_tlyqw5gcux9z-2 {
            list-style-type: none
        }

        ul.lst-kix_tlyqw5gcux9z-0 {
            list-style-type: none
        }

        .lst-kix_3k0redj75evo-1>li:before {
            content: "\0025cb  "
        }

        .lst-kix_3k0redj75evo-2>li:before {
            content: "\0025a0  "
        }

        .lst-kix_3k0redj75evo-3>li:before {
            content: "\0025cf  "
        }

        .lst-kix_3k0redj75evo-4>li:before {
            content: "\0025cb  "
        }

        .lst-kix_3k0redj75evo-6>li:before {
            content: "\0025cf  "
        }

        .lst-kix_3k0redj75evo-7>li:before {
            content: "\0025cb  "
        }

        .lst-kix_3k0redj75evo-5>li:before {
            content: "\0025a0  "
        }

        .lst-kix_qqsaobkzdtyb-6>li:before {
            content: "\0025cf  "
        }

        .lst-kix_3k0redj75evo-8>li:before {
            content: "\0025a0  "
        }

        .lst-kix_qqsaobkzdtyb-5>li:before {
            content: "\0025a0  "
        }

        .lst-kix_qqsaobkzdtyb-2>li:before {
            content: "\0025a0  "
        }

        .lst-kix_qqsaobkzdtyb-4>li:before {
            content: "\0025cb  "
        }

        .lst-kix_qqsaobkzdtyb-3>li:before {
            content: "\0025cf  "
        }

        .lst-kix_qqsaobkzdtyb-0>li:before {
            content: "\0025cf  "
        }

        .lst-kix_qqsaobkzdtyb-1>li:before {
            content: "\0025cb  "
        }

        ul.lst-kix_s62pfejojdna-8 {
            list-style-type: none
        }

        ul.lst-kix_s62pfejojdna-7 {
            list-style-type: none
        }

        ul.lst-kix_s62pfejojdna-6 {
            list-style-type: none
        }

        ul.lst-kix_s62pfejojdna-5 {
            list-style-type: none
        }

        ul.lst-kix_s62pfejojdna-4 {
            list-style-type: none
        }

        ul.lst-kix_s62pfejojdna-3 {
            list-style-type: none
        }

        ul.lst-kix_s62pfejojdna-2 {
            list-style-type: none
        }

        ul.lst-kix_s62pfejojdna-1 {
            list-style-type: none
        }

        ul.lst-kix_s62pfejojdna-0 {
            list-style-type: none
        }

        .lst-kix_vz0v84nlmagn-5>li:before {
            content: "\0025a0  "
        }

        .lst-kix_s62pfejojdna-1>li:before {
            content: "\0025cb  "
        }

        .lst-kix_s62pfejojdna-3>li:before {
            content: "\0025cf  "
        }

        .lst-kix_vz0v84nlmagn-7>li:before {
            content: "\0025cb  "
        }

        .lst-kix_x9pd3i4ln0xw-7>li:before {
            content: "\0025cb  "
        }

        .lst-kix_s62pfejojdna-5>li:before {
            content: "\0025a0  "
        }

        .lst-kix_s62pfejojdna-7>li:before {
            content: "\0025cb  "
        }

        .lst-kix_vz0v84nlmagn-1>li:before {
            content: "\0025cb  "
        }

        .lst-kix_x9pd3i4ln0xw-5>li:before {
            content: "\0025a0  "
        }

        .lst-kix_vz0v84nlmagn-3>li:before {
            content: "\0025cf  "
        }

        .lst-kix_ursa7dda2dhe-7>li:before {
            content: "\0025cb  "
        }

        .lst-kix_ii598iblnbs3-0>li:before {
            content: "\0025cf  "
        }

        .lst-kix_ursa7dda2dhe-3>li:before {
            content: "\0025cf  "
        }

        .lst-kix_ursa7dda2dhe-1>li:before {
            content: "\0025cb  "
        }

        .lst-kix_ursa7dda2dhe-5>li:before {
            content: "\0025a0  "
        }

        .lst-kix_etv82t5b4bev-6>li:before {
            content: "\0025cf  "
        }

        .lst-kix_ii598iblnbs3-4>li:before {
            content: "\0025cb  "
        }

        .lst-kix_ii598iblnbs3-2>li:before {
            content: "\0025a0  "
        }

        .lst-kix_etv82t5b4bev-8>li:before {
            content: "\0025a0  "
        }

        .lst-kix_ii598iblnbs3-8>li:before {
            content: "\0025a0  "
        }

        ul.lst-kix_qqsaobkzdtyb-8 {
            list-style-type: none
        }

        .lst-kix_ii598iblnbs3-6>li:before {
            content: "\0025cf  "
        }

        ul.lst-kix_x9pd3i4ln0xw-1 {
            list-style-type: none
        }

        ul.lst-kix_x9pd3i4ln0xw-2 {
            list-style-type: none
        }

        ul.lst-kix_x9pd3i4ln0xw-3 {
            list-style-type: none
        }

        ul.lst-kix_x9pd3i4ln0xw-4 {
            list-style-type: none
        }

        ul.lst-kix_x9pd3i4ln0xw-0 {
            list-style-type: none
        }

        ul.lst-kix_i20dswo3uxy1-4 {
            list-style-type: none
        }

        ul.lst-kix_qqsaobkzdtyb-1 {
            list-style-type: none
        }

        ul.lst-kix_i20dswo3uxy1-3 {
            list-style-type: none
        }

        ul.lst-kix_qqsaobkzdtyb-0 {
            list-style-type: none
        }

        ul.lst-kix_i20dswo3uxy1-2 {
            list-style-type: none
        }

        ul.lst-kix_qqsaobkzdtyb-3 {
            list-style-type: none
        }

        ul.lst-kix_i20dswo3uxy1-1 {
            list-style-type: none
        }

        ul.lst-kix_qqsaobkzdtyb-2 {
            list-style-type: none
        }

        ul.lst-kix_i20dswo3uxy1-8 {
            list-style-type: none
        }

        ul.lst-kix_x9pd3i4ln0xw-5 {
            list-style-type: none
        }

        ul.lst-kix_qqsaobkzdtyb-5 {
            list-style-type: none
        }

        ul.lst-kix_i20dswo3uxy1-7 {
            list-style-type: none
        }

        ul.lst-kix_x9pd3i4ln0xw-6 {
            list-style-type: none
        }

        ul.lst-kix_qqsaobkzdtyb-4 {
            list-style-type: none
        }

        ul.lst-kix_i20dswo3uxy1-6 {
            list-style-type: none
        }

        ul.lst-kix_x9pd3i4ln0xw-7 {
            list-style-type: none
        }

        ul.lst-kix_qqsaobkzdtyb-7 {
            list-style-type: none
        }

        ul.lst-kix_i20dswo3uxy1-5 {
            list-style-type: none
        }

        ul.lst-kix_x9pd3i4ln0xw-8 {
            list-style-type: none
        }

        ul.lst-kix_qqsaobkzdtyb-6 {
            list-style-type: none
        }

        ul.lst-kix_etv82t5b4bev-1 {
            list-style-type: none
        }

        ul.lst-kix_etv82t5b4bev-0 {
            list-style-type: none
        }

        .lst-kix_xp3ckenvlp1n-4>li:before {
            content: "\0025cb  "
        }

        .lst-kix_1k0xdcw10u1a-0>li:before {
            content: "\0025cf  "
        }

        .lst-kix_1k0xdcw10u1a-1>li:before {
            content: "\0025cb  "
        }

        .lst-kix_xp3ckenvlp1n-3>li:before {
            content: "\0025cf  "
        }

        .lst-kix_1k0xdcw10u1a-2>li:before {
            content: "\0025a0  "
        }

        .lst-kix_xp3ckenvlp1n-2>li:before {
            content: "\0025a0  "
        }

        .lst-kix_1k0xdcw10u1a-4>li:before {
            content: "\0025cb  "
        }

        .lst-kix_1k0xdcw10u1a-3>li:before {
            content: "\0025cf  "
        }

        .lst-kix_1k0xdcw10u1a-5>li:before {
            content: "\0025a0  "
        }

        .lst-kix_xp3ckenvlp1n-1>li:before {
            content: "\0025cb  "
        }

        .lst-kix_xp3ckenvlp1n-0>li:before {
            content: "\0025cf  "
        }

        .lst-kix_yi6k844ohu2-6>li:before {
            content: "\0025cf  "
        }

        .lst-kix_1k0xdcw10u1a-7>li:before {
            content: "\0025cb  "
        }

        .lst-kix_tlyqw5gcux9z-2>li:before {
            content: "\0025a0  "
        }

        .lst-kix_1k0xdcw10u1a-6>li:before {
            content: "\0025cf  "
        }

        .lst-kix_yi6k844ohu2-8>li:before {
            content: "\0025a0  "
        }

        .lst-kix_tlyqw5gcux9z-3>li:before {
            content: "\0025cf  "
        }

        .lst-kix_yi6k844ohu2-7>li:before {
            content: "\0025cb  "
        }

        .lst-kix_tlyqw5gcux9z-4>li:before {
            content: "\0025cb  "
        }

        .lst-kix_yi6k844ohu2-2>li:before {
            content: "\0025a0  "
        }

        .lst-kix_yi6k844ohu2-3>li:before {
            content: "\0025cf  "
        }

        .lst-kix_yi6k844ohu2-1>li:before {
            content: "\0025cb  "
        }

        .lst-kix_yi6k844ohu2-5>li:before {
            content: "\0025a0  "
        }

        .lst-kix_tlyqw5gcux9z-5>li:before {
            content: "\0025a0  "
        }

        .lst-kix_tlyqw5gcux9z-8>li:before {
            content: "\0025a0  "
        }

        .lst-kix_1k0xdcw10u1a-8>li:before {
            content: "\0025a0  "
        }

        .lst-kix_tlyqw5gcux9z-6>li:before {
            content: "\0025cf  "
        }

        .lst-kix_yi6k844ohu2-4>li:before {
            content: "\0025cb  "
        }

        .lst-kix_tlyqw5gcux9z-7>li:before {
            content: "\0025cb  "
        }

        .lst-kix_x9pd3i4ln0xw-0>li:before {
            content: "\0025cf  "
        }

        .lst-kix_x9pd3i4ln0xw-1>li:before {
            content: "\0025cb  "
        }

        ul.lst-kix_3k0redj75evo-3 {
            list-style-type: none
        }

        ul.lst-kix_3k0redj75evo-2 {
            list-style-type: none
        }

        .lst-kix_x9pd3i4ln0xw-3>li:before {
            content: "\0025cf  "
        }

        ul.lst-kix_3k0redj75evo-5 {
            list-style-type: none
        }

        ul.lst-kix_3k0redj75evo-4 {
            list-style-type: none
        }

        .lst-kix_x9pd3i4ln0xw-2>li:before {
            content: "\0025a0  "
        }

        ul.lst-kix_3k0redj75evo-7 {
            list-style-type: none
        }

        .lst-kix_yi6k844ohu2-0>li:before {
            content: "\0025cf  "
        }

        ul.lst-kix_3k0redj75evo-6 {
            list-style-type: none
        }

        ul.lst-kix_3k0redj75evo-8 {
            list-style-type: none
        }

        .lst-kix_tlyqw5gcux9z-1>li:before {
            content: "\0025cb  "
        }

        ul.lst-kix_3k0redj75evo-1 {
            list-style-type: none
        }

        ul.lst-kix_3k0redj75evo-0 {
            list-style-type: none
        }

        .lst-kix_tlyqw5gcux9z-0>li:before {
            content: "\0025cf  "
        }

        .lst-kix_xp3ckenvlp1n-5>li:before {
            content: "\0025a0  "
        }

        .lst-kix_xp3ckenvlp1n-6>li:before {
            content: "\0025cf  "
        }

        ul.lst-kix_etv82t5b4bev-7 {
            list-style-type: none
        }

        ul.lst-kix_etv82t5b4bev-6 {
            list-style-type: none
        }

        .lst-kix_xp3ckenvlp1n-7>li:before {
            content: "\0025cb  "
        }

        ul.lst-kix_etv82t5b4bev-8 {
            list-style-type: none
        }

        ul.lst-kix_etv82t5b4bev-3 {
            list-style-type: none
        }

        ul.lst-kix_etv82t5b4bev-2 {
            list-style-type: none
        }

        ul.lst-kix_etv82t5b4bev-5 {
            list-style-type: none
        }

        .lst-kix_xp3ckenvlp1n-8>li:before {
            content: "\0025a0  "
        }

        ul.lst-kix_etv82t5b4bev-4 {
            list-style-type: none
        }

        ul.lst-kix_xp3ckenvlp1n-8 {
            list-style-type: none
        }

        ul.lst-kix_xp3ckenvlp1n-7 {
            list-style-type: none
        }

        ul.lst-kix_xp3ckenvlp1n-6 {
            list-style-type: none
        }

        ul.lst-kix_xp3ckenvlp1n-5 {
            list-style-type: none
        }

        ul.lst-kix_xp3ckenvlp1n-4 {
            list-style-type: none
        }

        ul.lst-kix_xp3ckenvlp1n-3 {
            list-style-type: none
        }

        ul.lst-kix_xp3ckenvlp1n-2 {
            list-style-type: none
        }

        ul.lst-kix_xp3ckenvlp1n-1 {
            list-style-type: none
        }

        ul.lst-kix_xp3ckenvlp1n-0 {
            list-style-type: none
        }

        ul.lst-kix_1k0xdcw10u1a-0 {
            list-style-type: none
        }

        ul.lst-kix_ursa7dda2dhe-1 {
            list-style-type: none
        }

        .lst-kix_vz0v84nlmagn-4>li:before {
            content: "\0025cb  "
        }

        ul.lst-kix_ursa7dda2dhe-2 {
            list-style-type: none
        }

        ul.lst-kix_ursa7dda2dhe-3 {
            list-style-type: none
        }

        ul.lst-kix_ursa7dda2dhe-4 {
            list-style-type: none
        }

        .lst-kix_s62pfejojdna-0>li:before {
            content: "\0025cf  "
        }

        ul.lst-kix_ursa7dda2dhe-0 {
            list-style-type: none
        }

        .lst-kix_s62pfejojdna-2>li:before {
            content: "\0025a0  "
        }

        .lst-kix_vz0v84nlmagn-0>li:before {
            content: "\0025cf  "
        }

        .lst-kix_vz0v84nlmagn-8>li:before {
            content: "\0025a0  "
        }

        ul.lst-kix_ursa7dda2dhe-5 {
            list-style-type: none
        }

        .lst-kix_vz0v84nlmagn-6>li:before {
            content: "\0025cf  "
        }

        ul.lst-kix_ursa7dda2dhe-6 {
            list-style-type: none
        }

        .lst-kix_3k0redj75evo-0>li:before {
            content: "\0025cf  "
        }

        ul.lst-kix_ursa7dda2dhe-7 {
            list-style-type: none
        }

        ul.lst-kix_ursa7dda2dhe-8 {
            list-style-type: none
        }

        .lst-kix_s62pfejojdna-6>li:before {
            content: "\0025cf  "
        }

        .lst-kix_x9pd3i4ln0xw-8>li:before {
            content: "\0025a0  "
        }

        .lst-kix_s62pfejojdna-4>li:before {
            content: "\0025cb  "
        }

        .lst-kix_s62pfejojdna-8>li:before {
            content: "\0025a0  "
        }

        ul.lst-kix_1k0xdcw10u1a-8 {
            list-style-type: none
        }

        ul.lst-kix_1k0xdcw10u1a-7 {
            list-style-type: none
        }

        .lst-kix_x9pd3i4ln0xw-4>li:before {
            content: "\0025cb  "
        }

        ul.lst-kix_1k0xdcw10u1a-6 {
            list-style-type: none
        }

        ul.lst-kix_1k0xdcw10u1a-5 {
            list-style-type: none
        }

        ul.lst-kix_1k0xdcw10u1a-4 {
            list-style-type: none
        }

        ul.lst-kix_1k0xdcw10u1a-3 {
            list-style-type: none
        }

        .lst-kix_x9pd3i4ln0xw-6>li:before {
            content: "\0025cf  "
        }

        .lst-kix_vz0v84nlmagn-2>li:before {
            content: "\0025a0  "
        }

        ul.lst-kix_1k0xdcw10u1a-2 {
            list-style-type: none
        }

        ul.lst-kix_1k0xdcw10u1a-1 {
            list-style-type: none
        }

        .lst-kix_ursa7dda2dhe-6>li:before {
            content: "\0025cf  "
        }

        .lst-kix_ursa7dda2dhe-8>li:before {
            content: "\0025a0  "
        }

        .lst-kix_ii598iblnbs3-1>li:before {
            content: "\0025cb  "
        }

        .lst-kix_ursa7dda2dhe-2>li:before {
            content: "\0025a0  "
        }

        .lst-kix_ursa7dda2dhe-4>li:before {
            content: "\0025cb  "
        }

        .lst-kix_etv82t5b4bev-7>li:before {
            content: "\0025cb  "
        }

        ul.lst-kix_ii598iblnbs3-8 {
            list-style-type: none
        }

        ul.lst-kix_ii598iblnbs3-6 {
            list-style-type: none
        }

        ul.lst-kix_ii598iblnbs3-7 {
            list-style-type: none
        }

        ul.lst-kix_ii598iblnbs3-4 {
            list-style-type: none
        }

        ul.lst-kix_ii598iblnbs3-5 {
            list-style-type: none
        }

        ul.lst-kix_ii598iblnbs3-2 {
            list-style-type: none
        }

        .lst-kix_ii598iblnbs3-3>li:before {
            content: "\0025cf  "
        }

        ul.lst-kix_ii598iblnbs3-3 {
            list-style-type: none
        }

        ul.lst-kix_ii598iblnbs3-0 {
            list-style-type: none
        }

        ul.lst-kix_ii598iblnbs3-1 {
            list-style-type: none
        }

        .lst-kix_ii598iblnbs3-7>li:before {
            content: "\0025cb  "
        }

        .lst-kix_ii598iblnbs3-5>li:before {
            content: "\0025a0  "
        }

        ul.lst-kix_yi6k844ohu2-7 {
            list-style-type: none
        }

        ul.lst-kix_yi6k844ohu2-6 {
            list-style-type: none
        }

        ul.lst-kix_yi6k844ohu2-5 {
            list-style-type: none
        }

        ul.lst-kix_yi6k844ohu2-4 {
            list-style-type: none
        }

        ul.lst-kix_yi6k844ohu2-3 {
            list-style-type: none
        }

        ul.lst-kix_yi6k844ohu2-2 {
            list-style-type: none
        }

        ul.lst-kix_yi6k844ohu2-1 {
            list-style-type: none
        }

        ul.lst-kix_yi6k844ohu2-0 {
            list-style-type: none
        }

        ul.lst-kix_yi6k844ohu2-8 {
            list-style-type: none
        }

        ol {
            margin: 0;
            padding: 0
        }

        table td,
        table th {
            padding: 0
        }

        .c16 {
            padding-top: 0pt;
            border-top-width: 0pt;
            border-bottom-color: null;
            padding-bottom: 0pt;
            line-height: 1.15;
            border-top-style: solid;
            border-bottom-width: 0pt;
            border-top-color: null;
            border-bottom-style: solid;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        .c0 {
            padding-top: 0pt;
            border-top-width: 0pt;
            border-bottom-color: null;
            padding-bottom: 0pt;
            line-height: 1.15;
            border-top-style: solid;
            border-bottom-width: 0pt;
            border-top-color: null;
            border-bottom-style: solid;
            orphans: 2;
            widows: 2;
            text-align: justify
        }

        .c9 {
            background-color: #ffffff;
            padding-top: 12pt;
            padding-bottom: 12pt;
            line-height: 1.15;
            orphans: 2;
            widows: 2;
            text-align: right
        }

        .c1 {
            color: #333333;
            font-weight: 400;
            text-decoration: none;
            vertical-align: baseline;
            font-size: 12.5pt;
            font-style: normal
        }

        .c18 {
            padding-top: 0pt;
            padding-bottom: 0pt;
            line-height: 1.15;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        .c13 {
            padding-top: 0pt;
            padding-bottom: 0pt;
            line-height: 1.15;
            orphans: 2;
            widows: 2;
            text-align: center
        }

        .c17 {
            text-decoration-skip-ink: none;
            font-size: 12.5pt;
            -webkit-text-decoration-skip: none;
            color: #1155cc;
            text-decoration: underline
        }

        .c4 {
            text-decoration: none;
            vertical-align: baseline;
            font-style: normal
        }

        .c15 {
            color: #000000;
            font-weight: 400;
            font-size: 10pt
        }

        .c8 {
            background-color: #ffffff;
            max-width: 468pt;
            padding: 72pt 72pt 72pt 72pt
        }

        .c6 {
            color: #333333;
            font-weight: 700;
            font-size: 13pt
        }

        .c3 {
            font-size: 12.5pt;
            color: #333333;
            font-weight: 700
        }

        .c10 {
            color: #000000;
            font-weight: 400;
            font-size: 11pt
        }

        .c19 {
            color: #333333;
            font-weight: 700;
            font-size: 19pt
        }

        .c2 {
            padding-left: 0pt;
        }

        .c7 {
            padding: 0;
            margin: 0
        }

        .c14 {
            color: #333333;
            font-size: 12.5pt
        }

        .c12 {
            color: inherit;
            text-decoration: inherit
        }



        .title {
            padding-top: 0pt;
            color: #000000;
            font-size: 26pt;
            padding-bottom: 3pt;
            line-height: 1.15;
            page-break-after: avoid;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        .subtitle {
            padding-top: 0pt;
            color: #666666;
            font-size: 15pt;
            padding-bottom: 16pt;
            line-height: 1.15;
            page-break-after: avoid;
            orphans: 2;
            widows: 2;
            text-align: left
        }
