

    .tns-slider>.tns-item {
        font-size: 40px;
        opacity:.7;
        transform: scale(.8);
        transform-origin:center left;
        padding: 10px 0;

        @media only screen and (max-width: 1200px) {
            font-size: 30px;
            transform: none;
            padding: 0;
          }
    }

    .tns-slide-active {
        opacity:.5;
        transform: scale(.8);
        text-align: left;

        @media only screen and (max-width: 1200px) {
            font-size: 30px;
            transform: none;
          }
        
        
        &:nth-of-type(1) {
            opacity: 1;
            transition: all .5s ease-in-out;
            transform: scale(1);
        }
    }

    .slider-frame {
        overflow: unset !important;
    }
    .tns-carousel {
        h1:nth-of-type(1) {
            opacity: 1;
            transition: all .5s ease-in-out;
        }

        h1 {
            padding: 5px 0;
        }
    }

    .slider-wrapper {
        display: flex;
        align-items: center;
        margin-bottom: 60px;

        @media only screen and (max-width: 1200px) {
            display: block;
            text-align: center;
          }

        .main-text {
            width: 220px;
            font-size: 40px;

            @media only screen and (max-width: 1200px) {
                width: 100%;
                margin-bottom: 30px;
              }
        }
    }

    .slider___action {
        text-align: center;
        button {
            margin-right: 1px;
            background: #0a7ef9;
            color: white;
            font-size: 16px;
            padding: 10px 20px;
            height: auto;
        }
    }